$(document).ready(function () {
    $('#slider-top').owlCarousel({
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [980, 1],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        autoPlay: false,
        autoHeight:false,
        navigation: true,
        navigationText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
        pagination: true
    });

    $('#slider-product').owlCarousel({
        items: 2,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [980, 1],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        autoPlay: false,
        autoHeight:false,
        navigation: true,
        navigationText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
        pagination: false
    });

    $('#news').owlCarousel({
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [980, 1],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        autoPlay: false,
        autoHeight:false,
        navigation: true,
        navigationText: ['<i class="fa fa-chevron-left left-slide" aria-hidden="true"></i>', '<i class="fa fa-chevron-right right-slide" aria-hidden="true"></i>'],
        pagination: false
    });
    
    $('#partners').owlCarousel({
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [980, 1],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        autoPlay: 3000,
        autoHeight:false,
        navigation: true,
        navigationText: ['<i class="fa fa-chevron-left left-slide" aria-hidden="true"></i>', '<i class="fa fa-chevron-right right-slide" aria-hidden="true"></i>'],
        pagination: false
    });

    $('#slide-thumbnail').owlCarousel({
        items: 3,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [980, 3],
        itemsTablet: [768,5],
        itemsMobile: [479, 3],
        autoPlay: false,
        autoHeight:false,
        navigation: true,
        navigationText: ['<i class="fa fa-chevron-left left-slide" aria-hidden="true"></i>', '<i class="fa fa-chevron-right right-slide" aria-hidden="true"></i>'],
        pagination: false
    });
    $('#featur-slider').owlCarousel({
        items: 4,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [980, 3],
        itemsTablet: [768,2],
        itemsMobile: [479, 1],
        autoPlay: false,
        autoHeight:false,
        navigation: true,
        navigationText: ['<i class="fa fa-chevron-left left-slide" aria-hidden="true"></i>', '<i class="fa fa-chevron-right right-slide" aria-hidden="true"></i>'],
        pagination: false
    });

    $(".left-menu .dop .slide-menu").on("click", function(){
        if(!$(this).hasClass('active')){
          $(".left-menu .dop .slide-menu").removeClass("active").siblings('.sub-category').slideUp(200);
            $(this).addClass('active');
            $(this).siblings('.sub-category').slideDown(200);
        }else{
            $(this).removeClass('active').siblings('.sub-category').slideUp();
        }
    });

    $(".left-menu .dop-level-2 .slide-menu-2").on("click", function(){
        if(!$(this).hasClass('active')){
          $(".left-menu .dop-level-2 .slide-menu-2").removeClass("active").siblings('.sub-category-2').slideUp(200);
            $(this).addClass('active');
            $(this).siblings('.sub-category-2').slideDown(200);
        }else{
            $(this).removeClass('active').siblings('.sub-category-2').slideUp();
        }
    });
    
    $('#call-back-button').click(function() {
        $('#callback-modal').show();
        $('.modal-close').show();
    });
    $('.modal-close').click(function() {
        $('#callback-modal').hide();
        $('#feedback-modal').hide();
        $('.modal-close').hide();
        $('.callback-message').hide();
        $('.right-panel-mob .info-mod').hide();
    });
    $('.close').click(function() {
        $('#callback-modal').hide();
        $('#feedback-modal').hide();
        $('.modal-close').hide();
        $('.callback-message').hide();
    });

    $( ".fa-flag" ).click(function() {
        $( ".flag" ).toggleClass( "flag-active" );
    });
    $(".flag a").click(function() {
        $(".flag").toggleClass( "flag-active" );
    });

    var screenWidth = $( window ).width();
    if (screenWidth < 473) {
      $('.right-panel').addClass('right-panel-mob');
    }
    else{
        $('.right-panel').removeClass('right-panel-mob');
    }

    function mobButt() {
        $('.right-panel-mob #mode-but').click(function() {
            $(this).siblings('.info-mod').show();
            $('.modal-close').show();
        });

        $('.right-panel-mob #mail-but').click(function() {
            $(this).siblings('.info-mod').show();
            $('.modal-close').show();
        });
    }
    mobButt();

    $(window).resize(function () {
        var curW = $(this).width();
        if (curW < (473 )) {
            $('.right-panel').addClass('right-panel-mob');
            $('.info-mod').hide();

            mobButt();
        }
        else{
            $('.right-panel').removeClass('right-panel-mob');
            $('.info-mod').show();
            $('.modal-close').hide();
            $('#callback-modal').hide();

            $('#mode-but').click(function() {
                $('.modal-close').hide();
            });
            $('#mail-but').click(function() {
                $('.modal-close').hide();
            });
        }
    });

    $('#all-info').click(function () {
        $('#all-description').slideToggle();
        $(this).toggleClass('active');
        if ($(this).hasClass('active')){
            $(this).text('Скрыть');
        } else{
            $(this).text('Больше информации');
        }

    });
    $('.thumbnail-item').click(function() {
        $(this).parents('.owl-item').addClass('selected').siblings().removeClass('selected');
    });

    /*zoom image

    $("#img-lg").elevateZoom();*/

    $('#feedback').click(function() {
        $('#feedback-modal').show();
        $('.modal-close').show();
    });

    if ($(window).width() < 767 - 17) {
        $('.facebook').appendTo('.contact-product');
        $('.checkbox-description').appendTo('.checkbox-mob');


    }

    $(window).resize(function () {
        var curW = $(this).width();
        if (curW < (767 - 17)) {
            $('.facebook').appendTo('.contact-product');

            $('#img-lg').removeClass('jqzoom');
        }
        else {
            $('#img-lg').addClass('jqzoom');
        }
    });
    $('.mfp-image-holder').click(function() {
        $('.common-home').attr('style', 'margin-right: 0;');
    });

    $('.img-gallery img').click(function() {
        $(this).attr('href', $(this).attr('src'));
        var screenHeight = $(screen.height) + 'px';
        var documentHeight = $(document).height() + 'px';
        if(screenHeight > documentHeight){
            $('.common-home').attr('style', 'margin-right: 20px;');
        }
    });

    $('.img-gallery img').magnificPopup({
        type: 'image'
    });


});


